<template>
  <div class="content">
    <div class="page-content">
      <div v-if="currentQuestion">
        <h3>
          {{ currentQuestionIndex + 1 }}. <span style="color: #00c064;">{{currentQuestion.qType}}</span> {{ currentQuestion.qTitle }}
        </h3>
        <van-divider />
        <div v-for="n in currentQuestion.sNum" :key="n" style="margin-top: 8px" class="item-content">
          <van-button
              :class="getButtonClass(n)"
              @click="toggleAnswer(n)"
              :disabled="userAnswers[currentQuestionIndex]?.answered"
              style="width: 100%; text-align: left !important; min-height: 48px; height: auto; padding: 10px"
          >
            <div style="width: 100%; text-align: left">{{ currentQuestion['s' + n] }}</div>
          </van-button>
        </div>
        <van-divider />
        <div v-if="userAnswers[currentQuestionIndex]?.answered" style="margin-top: 10px">
          <div v-if="isAnswerCorrect !== null">
            <span v-if="isAnswerCorrect" style="color: #7fb185;">回答正确</span>
            <span v-else style="color: red">回答错误</span>
          </div>

        </div>
        <div v-if="userAnswers[currentQuestionIndex]?.answered || currentQuestion.userAnswer" style="margin-top: 10px">
          正确答案: {{ getCorrectAnswerLetters() }}
        </div>
        <van-divider />
        <div style="margin-top: 20px; display: flex; justify-content: right">
          <van-button @click="prevQuestion" :disabled="currentQuestionIndex <= 0" type="primary"
                      style="min-width: 100px;">上一题
          </van-button>
          <van-button @click="nextQuestion" :disabled="currentQuestionIndex >= libDataList.length - 1" type="primary"
                      style="margin-left: 18px; min-width: 100px">下一题
          </van-button>
          <van-button @click="confirmAnswers" :disabled="userAnswers[currentQuestionIndex]?.answered" type="success"
                      style="margin-left: 18px; min-width: 80px">确定
          </van-button>
        </div>
      </div>
      <van-divider />
      <div class="navigation" style="display: flex; align-items: center; margin-top: 30px">
        <van-field type="number" v-model.number="jumpIndex" placeholder="跳转到题号" min="1" :max="libDataList.length"
                   style="width: 100px; margin-left: -12px"/>
        <van-button @click="jumpToQuestion">跳转</van-button>
        <span style="margin-left: 10px">题目总数：{{ libDataList.length }}</span>
      </div>
      <div style="height: 16px"></div>
    </div>
  </div>
</template>

<script>
import {ref, computed, onMounted} from "vue";

export default {
  props: {
    selectedLib: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const libDataList = ref([]);
    const currentQuestionIndex = ref(0);
    const userAnswers = ref([]);
    const jumpIndex = ref(''); // For input to jump to question

    const libs = ref(JSON.parse(localStorage.getItem('libs') || '[]'));

    const currentLib = computed(() => {
      return libs.value.find(lib => lib.describe.title === props.selectedLib);
    });

    const currentQuestion = computed(() => {
      return libDataList.value[currentQuestionIndex.value] || {};
    });

    // Load user answers from the current library or initialize if not present
    const loadUserAnswers = () => {
      userAnswers.value = libDataList.value.map((question) => {
        return question.userAnswer || {answers: [], answered: false};
      });
    };

    const init = () => {
      if (currentLib.value) {
        libDataList.value = currentLib.value.QSList;
        loadUserAnswers(); // Load answers after setting up the libDataList
      }
    };

    // Call init on component mount
    onMounted(init);

    const toggleAnswer = (n) => {
      if (!userAnswers.value[currentQuestionIndex.value].answered) {
        const questionAnswers = userAnswers.value[currentQuestionIndex.value].answers;
        const answerIndex = questionAnswers.indexOf(n);
        if (answerIndex === -1) {
          questionAnswers.push(n);
        } else {
          questionAnswers.splice(answerIndex, 1);
        }
      }
    };

    const confirmAnswers = () => {
      userAnswers.value[currentQuestionIndex.value].answered = true;
      updateLocalStorage();
    };

    const getButtonClass = (n) => {
      const questionAnswers = userAnswers.value[currentQuestionIndex.value].answers;
      if (questionAnswers.includes(n)) {
        if (userAnswers.value[currentQuestionIndex.value].answered) {
          return currentQuestion.value.ans[n - 1] === 1 ? 'correct' : 'incorrect';
        }
        return 'selected';
      }
      return '';
    };

    const prevQuestion = () => {
      if (currentQuestionIndex.value > 0) {
        currentQuestionIndex.value--;
      }
    };

    const nextQuestion = () => {
      if (currentQuestionIndex.value < libDataList.value.length - 1) {
        currentQuestionIndex.value++;
      }
    };

    const jumpToQuestion = () => {
      const index = jumpIndex.value - 1; // Convert to 0-based index
      if (index >= 0 && index < libDataList.value.length) {
        currentQuestionIndex.value = index;
      }
    };

    const updateLocalStorage = () => {
      // Update the local storage with the new user answers
      const libIndex = libs.value.indexOf(currentLib.value);
      libs.value[libIndex].QSList[currentQuestionIndex.value].userAnswer = userAnswers.value[currentQuestionIndex.value];
      localStorage.setItem('libs', JSON.stringify(libs.value));
    };
    const getCorrectAnswerLetters = () => {
      // 假设ans是一个长度与选项个数相同的数组，其中正确答案的位置为1，其余为0
      return currentQuestion.value.ans
          .map((isCorrect, index) => isCorrect === 1 ? String.fromCharCode(65 + index) : null)
          .filter(letter => letter !== null)
          .join(', ');
    };
    const isAnswerCorrect = computed(() => {
      // 确保用户已经回答了当前问题
      if (!userAnswers.value[currentQuestionIndex.value]?.answered) {
        return null; // 用户还没有回答
      }

      // 获取用户的答案数组
      const userAnswer = userAnswers.value[currentQuestionIndex.value].answers;
      // 获取正确答案的索引数组
      const correctAnswers = currentQuestion.value.ans
          .map((isCorrect, index) => isCorrect === 1 ? index + 1 : -1)
          .filter(index => index !== -1);

      // 如果用户的答案数量与正确答案的数量不同，则回答错误
      if (userAnswer.length !== correctAnswers.length) {
        return false;
      }

      // 对用户答案和正确答案进行排序
      const sortedUserAnswer = [...userAnswer].sort();
      const sortedCorrectAnswers = [...correctAnswers].sort();
      console.log(sortedUserAnswer)
      console.log(sortedCorrectAnswers)
      console.log(sortedUserAnswer.every((value, index) => value === sortedCorrectAnswers[index]))
      // 逐一比较排序后的用户答案和正确答案
      return sortedUserAnswer.every((value, index) => value === sortedCorrectAnswers[index]);
    });


    return {
      isAnswerCorrect,
      getCorrectAnswerLetters,
      currentQuestion,
      currentQuestionIndex,
      userAnswers,
      toggleAnswer,
      confirmAnswers,
      getButtonClass,
      prevQuestion,
      nextQuestion,
      jumpToQuestion,
      jumpIndex,
      libDataList,
    };
  },
};
</script>


<style scoped lang="scss">
.page-content {
  width: calc(100% - 32px);
  min-height: calc(100% - 32px);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1px 16px 0 16px;
  margin-top: 16px;
}

.content {
  background-color: #f3f3f3;
  width: calc(100vw - 32px);
  padding: 0 16px;
  height: calc(100vh - 90px);
  overflow-x: hidden;
  overflow-y: auto;
}

/* 选中但未确认的答案 */
button.selected:not(:disabled) {
  background-color: #4f94c9;
  color: white;
  border: none;
}

/* 确认后正确的答案 */
button.correct {
  background-color: green;
  color: white;
  border: none;
}

/* 确认后错误的答案 */
button.incorrect:not(:disabled) {
  background-color: red;
  color: white;
  border: none;
}

/* 禁用的按钮，无论正确与否 */
button:disabled {
  background-color: #808080; /* 默认禁用颜色 */
  color: white;
  border: none;
}

/* 确认后正确答案的禁用按钮 */
button.correct:disabled {
  background-color: darkgreen; /* 自定义禁用时正确答案的颜色 */
  color: white;
  border: none;
}

/* 确认后错误答案的禁用按钮 */
button.incorrect:disabled {
  background-color: darkred; /* 自定义禁用时错误答案的颜色 */
  color: white;
  border: none;
}

.navigation {
  margin-top: 20px;
}

.item-content {
  ::v-deep(.van-button__content) {
    justify-content: start !important;
  }
}

</style>
