<template>
  <van-popup v-model:show="showPicker" round position="bottom">
    <van-picker
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
        @change="onChange"
    />
  </van-popup>
</template>
<script>
import {ref} from "vue";

export default {
  props: {
    lib: {
      type: String,
      default: "",
    },
  },
  emits: ['onSelected'],
  setup(props, ctx) {
    const init = () => {
      let libsStore = localStorage.getItem('libs');
      let libs = [];
      if (libsStore) {
        libs = JSON.parse(libsStore).map((lib) => {
          return {"text": lib.describe.title, "value": lib.describe.title}
        });
      }
      columns.value = libs;
    };
    const selected = ref();
    const showPicker = ref(false);
    let libsStore = localStorage.getItem('libs');
    let libs = [];
    if (libsStore) {
      libs = JSON.parse(libsStore).map((lib) => {
        return {"text": lib.describe.title, "value": lib.describe.title}
      });
    }
    const columns = ref(libs);
    console.log(columns.value)
    const onConfirm = (value) => {
      ctx.emit('onSelected', value.selectedValues[0]);
      showPicker.value = false;
    };
    const onChange = (value) => {
      console.log(value);
      selected.value = value.selectedValues[0];
    };
    return {
      init,
      columns,
      onConfirm,
      showPicker,
      onChange,
    };
  },
}
</script>