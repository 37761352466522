<template>
  <div class="content">
    <van-nav-bar title="小窝刷题">
      <template #left>
        <span @click="onAbout">关于</span>
        <span style="color: #f00; margin-left: 10px" @click="onDelete" v-if="selectedLib">删除</span>

      </template>
      <template #right>
        <div>
          <span @click="triggerFileInput">导入</span>
          <span style="margin-left: 10px" @click="onReset" v-if="selectedLib">重置</span>
        </div>
        <!-- 隐藏的文件输入 -->
        <input
            type="file"
            ref="fileInput"
            accept=".txt"
            style="display: none"
            @change="handleFileChange"
        />
      </template>
    </van-nav-bar>
    <van-field
        v-model="selectedLib"
        is-link
        readonly
        label="题库"
        placeholder="选择题库"
        @click="onSelectLib"
    />
    <SelectLib ref="selectLibRef" @onSelected="onSelected"/>
    <QsList :key="'qs' + selectedLib + refreshKey" :selectedLib="selectedLib" v-if="selectedLib"/>
    <div v-else class="empty">
      <van-empty description="请选择题库"/>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import {NavBar, showConfirmDialog, showDialog} from 'vant';
import SelectLib from "@/components/SelectLib.vue";
import QsList from "@/components/QsList.vue";


export default {
  components: {
    QsList,
    SelectLib,
    [NavBar.name]: NavBar
  },
  setup() {
    const selectedLib = ref('');
    const selectLibRef = ref();
    const refreshKey = ref();
    const onClickLeft = () => {
      console.log('点击返回');
    };
    const onSelectLib = () => {
      console.log('选择题库');
      selectLibRef.value.init();
      selectLibRef.value.showPicker = true;
    };
    return {
      onClickLeft,
      selectedLib,
      selectLibRef,
      onSelectLib,
      refreshKey,
    };
  },
  methods: {
    onAbout() {
      showDialog({
        message: `
        互助QQ群：910914196 （题库共享、教程视频等，加群验证密码：海纳百川、自强不息）\n
        题库文件转换：https://xwst.crysu.com/#/pc/convert\n
        题库文件转换教程：https://xwst.crysu.com
        `,
      }).then(() => {
        // on confirm
      });
    },
    onDelete() {
      if (!this.selectedLib) {
        return;
      }
      showConfirmDialog({
        title: '提示',
        message: `是否确认删除题库【${this.selectedLib}】？`,
      }).then(() => {
        let libs = localStorage.getItem('libs');
        libs = libs ? JSON.parse(libs) : [];
        const newLibs = libs.filter(lib => lib.describe.title !== this.selectedLib);
        localStorage.setItem('libs', JSON.stringify(newLibs));
        this.selectedLib = '';
      }).catch(() => {
        // on cancel
      });
    },
    onReset() {
      if (!this.selectedLib) {
        return;
      }
      showConfirmDialog({
        title: '提示',
        message: `是否确认重置题库【${this.selectedLib}】的答题记录？`,
      }).then(() => {
        let libs = localStorage.getItem('libs');
        libs = libs ? JSON.parse(libs) : [];
        const lib = libs.find(lib => lib.describe.title === this.selectedLib);
        console.log(lib)
        lib['QSList'].forEach((question) => {
          delete question.userAnswer;
        });
        localStorage.setItem('libs', JSON.stringify(libs));
        this.refreshKey = new Date().getTime();
      }).catch(() => {
        // on cancel
      });

    },
    onSelected(selected) {
      this.selectedLib = selected;
    },
// 当按钮被点击时调用
    triggerFileInput() {
      // 触发文件输入的点击事件
      this.$refs.fileInput.click();
    },
    // 当文件被选择时调用
    handleFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        const file = files[0];
        if (file.type === "text/plain") {
          this.importLib(file);
        } else {
          alert("请选择一个 TXT 文件。");
        }
      }
    },
    importLib(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          // 尝试将读取的文本解析为 JSON 对象
          const jsonObj = JSON.parse(e.target.result);
          // 处理 jsonObj ...
          console.log("JSON 对象:", jsonObj);
          let libs = localStorage.getItem('libs');
          libs = libs ? JSON.parse(libs) : [];
          const titles = libs.map(lib => lib.describe.title);
          if (titles.includes(jsonObj.describe.title)) {
            alert('已存在该题库');
            return;
          }
          libs.push(jsonObj);
          localStorage.setItem('libs', JSON.stringify(libs));
          alert('导入成功')
        } catch (error) {
          // 如果内容不是有效的 JSON，捕获并处理错误
          console.error("解析错误:", error);
          alert("文件内容不是有效的 JSON 字符串。");
        }
      };
      reader.onerror = (e) => {
        console.error("读取文件时发生错误:", e);
      };
      reader.readAsText(file); // 以文本形式读取文件
    },
  }
}
</script>

<style scoped>
.content {

}

.empty {
  height: calc(100vh - 90px);
  width: 100vw;
  background-color: #f3f3f3;
}
</style>
